<template>
  <client-only>
    <div class="header" v-if="resolution.width > resolution.notebook">

      <!-- 광고 -->
      <component :is="AdvertiseTee" />

      <component :is="PcOurHeader" v-if="mode === 'our'" />
      <component :is="PcExpansionHeader" v-else-if="mode === 'expansion'" />
      <component :is="PcContainerHeader" v-else-if="mode === 'container'" />
      <component :is="PcContainerHeader" v-else-if="mode === 'me'" />
      <component :is="PcDefaultHeader" v-else />
      
    </div>
    <div class="header mobile" v-else>

      <component :is="MobileOurHeader" v-if="mode === 'our'" />
      <component :is="MobileExpansionHeader" v-else-if="mode === 'expansion'" />
      <component :is="MobileContainerHeader" v-else-if="mode === 'container'" />
      <component :is="MobileContainerHeader" v-else-if="mode === 'me'" />
      <component :is="MobileDefaultHeader" v-else />

    </div>
  </client-only>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'
// import PcDefaultHeader from '~/component/layout/default-header-pc.vue'
// import MobileDefaultHeader from '~/component/layout/default-header-mobile.vue'
// import PcExpansionHeader from '~/component/layout/expansion-header-pc.vue'
// import MobileExpansionHeader from '~/component/layout/expansion-header-mobile.vue'
// import PcContainerHeader from '~/component/layout/container-header-pc.vue'
// import MobileContainerHeader from '~/component/layout/container-header-mobile.vue'
// import PcOurHeader from '~/component/layout/our-header-pc.vue'
// import MobileOurHeader from '~/component/layout/our-header-mobile.vue'
// import AdvertiseTee from '~/component/advertise/tee.vue'

export default {
  setup () {

    // 글로벌 스토어
    const { layout } = storeToRefs(useGlobalStore())
    const { resolution } = storeToRefs(useGlobalStore())

    // 메모리 비우기
    function restore () {
      PcDefaultHeader.value = null
      MobileDefaultHeader.value = null
      PcExpansionHeader.value = null
      MobileExpansionHeader.value = null
      PcContainerHeader.value = null
      MobileContainerHeader.value = null
      PcOurHeader.value = null
      MobileOurHeader.value = null
      AdvertiseTee.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const PcDefaultHeader: any = shallowRef(null)
    const MobileDefaultHeader: any = shallowRef(null)
    const PcExpansionHeader: any = shallowRef(null)
    const MobileExpansionHeader: any = shallowRef(null)
    const PcContainerHeader: any = shallowRef(null)
    const MobileContainerHeader: any = shallowRef(null)
    const PcOurHeader: any = shallowRef(null)
    const MobileOurHeader: any = shallowRef(null)
    const AdvertiseTee: any = shallowRef(null)

    const loadComponent = async () => {
      const PcDefaultHeader_load = await import('~/component/layout/default-header-pc.vue')
      const MobileDefaultHeader_load = await import('~/component/layout/default-header-mobile.vue')
      const PcExpansionHeader_load = await import('~/component/layout/expansion-header-pc.vue')
      const MobileExpansionHeader_load = await import('~/component/layout/expansion-header-mobile.vue')
      const PcContainerHeader_load = await import('~/component/layout/container-header-pc.vue')
      const MobileContainerHeader_load = await import('~/component/layout/container-header-mobile.vue')
      const PcOurHeader_load = await import('~/component/layout/our-header-pc.vue')
      const MobileOurHeader_load = await import('~/component/layout/our-header-mobile.vue')
      const AdvertiseTee_load = await import('~/component/advertise/tee.vue')

      PcDefaultHeader.value = PcDefaultHeader_load.default || PcDefaultHeader_load
      MobileDefaultHeader.value = MobileDefaultHeader_load.default || MobileDefaultHeader_load
      PcExpansionHeader.value = PcExpansionHeader_load.default || PcExpansionHeader_load
      MobileExpansionHeader.value = MobileExpansionHeader_load.default || MobileExpansionHeader_load
      PcContainerHeader.value = PcContainerHeader_load.default || PcContainerHeader_load
      MobileContainerHeader.value = MobileContainerHeader_load.default || MobileContainerHeader_load
      PcOurHeader.value = PcOurHeader_load.default || PcOurHeader_load
      MobileOurHeader.value = MobileOurHeader_load.default || MobileOurHeader_load
      AdvertiseTee.value = AdvertiseTee_load.default || AdvertiseTee_load
    }
    loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      layout,
      resolution,

      // 컴포넌트
      PcDefaultHeader,
      MobileDefaultHeader,
      PcExpansionHeader,
      MobileExpansionHeader,
      PcContainerHeader,
      MobileContainerHeader,
      PcOurHeader,
      MobileOurHeader,
      AdvertiseTee
    }
  },
  props: {
    mode: {
      type: String,
      default () {
        return ''
      }
    }
  }
}
</script>